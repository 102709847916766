import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/layout';
import { SimpleParagraphContent } from '../templates/template-base'
import { Section, ContactsSection, TeaserSection, findNamedBacgroundAsset, BaseContentSection } from '../components/section';
import {
  HeaderText1,
  HeaderText2,
  BodyTextL,
  BodyTextS,
  TagText,
} from '../components/text';
import { ButtonLink } from '../components/link';

import createTranslate from '../utils/translator-provider';
import { PAGES_INFO, MEDIA_INFO } from '../utils/pages-info';

export default ({ data }) => {

    const translate = createTranslate(data?.page?.translations);

    const contentBackgroundAsset = findNamedBacgroundAsset("SuomiAreena Briefly in English content background", data?.page?.sectionBackgrounds);
    const contacts = data.page.itemGroups.filter(x => x.title == 'SuomiAreena Briefly in English contacts')[0].items ?? []

    const contentSections = data.page?.pageSections?.flatMap(section => section?.sectionParagraphs ?? []) ?? []

    return (
      <Layout
        title={data.page.title}
        description={data.page.seoDescription}
        path={PAGES_INFO.brieflyInEnglishPage.path}
      >
        <TeaserSection
          backgroundAssets={data?.page?.sectionBackgrounds}
          sectionName="Briefly in english page teaser background"
          title={translate('brieflyInEnglishPage.teaserSection.title')}
          body={translate('brieflyInEnglishPage.teaserSection.body')}
        />
        <BaseContentSection backgroundAsset={contentBackgroundAsset}>
            <div className="col-span-1 md:col-span-2 flex flex-col gap-10 px-12 md:px-0">
              {contentSections && contentSections.map((paragraph, idx)=>{
                return <SimpleParagraphContent key={idx} contentJSON={paragraph.content.json}/>
              })}
            </div>
            <div className="md:hidden col-span-1 h-12"/>
            <div className="flex flex-col sm:flex-row lg:flex-col gap-10">
              <ContactsSection contacts={contacts} headerText={translate('brieflyInEnglishPage.contactUs.heading')}/>
            </div>
          </BaseContentSection>
      </Layout>
    );
  };


  export const query = graphql`
  query {
    page: contentfulPage(name: { eq: "SuomiAreena Briefly in english" }) {
      title
      seoDescription
      itemGroups {
        title
        header
        items {
        ... on ContentfulContact {
            email
            title
            lastName
            firstName
          }
          ... on ContentfulItemGroup {
            id
            header
            title
            items {
              ... on ContentfulCampaign {
                id
                header
                previewText {
                  previewText
                }
                previewLinkText
                slug
                internal {
                  type
                }
                previewBackgroundAsset {
                  asset {
                    file {
                      contentType
                      url
                    }
                    localFile {
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 3000) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                    fluid(maxWidth: 3000) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                  }
                  brightness
                  useShadowForText
                  objectPositionVertical
                  objectPositionHorizontal
                }
              }
            }
          }
        }
      }
      sectionBackgrounds {
        name
        asset {
          file {
            contentType
            url
          }
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          fluid(maxWidth: 3000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        brightness
        useShadowForText
        objectPositionVertical
        objectPositionHorizontal
      }
      pageSections {
        sectionParagraphs {
          ... on ContentfulCampaignContentParagraph {
            name
            columnsToTake
            content {
              json
            }
          }
        }
      }
      translations {
        key
        value {
          value
        }
      }
    }
  }
`;
